/**
 * LoadingIndicator
 *
 * Loading spinner used while loading page containers
 */

import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

// STYLES
const Loader = styled.div`
  text-align: center;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
  min-height: calc(100vh - 170px);
`;

/** LoadingIndicator component */
const LoadingIndicator = () => (
  <Loader>
    <Spin size="large" />
  </Loader>
);

export default LoadingIndicator;
