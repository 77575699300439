/*
 * Validation Actions
 *
 */

import { LOAD_VALIDATION, LOAD_VALIDATION_SUCCESS, LOAD_VALIDATION_ERROR } from './constants';

/**
 * Load the validation, this action starts the request saga
 *
 * @param  {string} token The auth token
 * @param  {string} date The validation date
 *
 * @return {object} An action object with a type of LOAD_VALIDATION passing token and date
 */
export function loadValidation(date, filters, token, entitlements) {
  return {
    type: LOAD_VALIDATION,
    date,
    filters,
    token,
    entitlements,
  };
}

/**
 * Dispatched when the validation is loaded by the request saga
 *
 * @param  {object} validation The validation data
 *
 * @return {object}  An action object with a type of LOAD_VALIDATION_SUCCESS passing the data
 */
export function validationLoaded(validation) {
  return {
    type: LOAD_VALIDATION_SUCCESS,
    validation,
  };
}

/**
 * Dispatched when loading the validation fails
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of LOAD_VALIDATION_ERROR passing the error
 */
export function validationLoadingError(error) {
  return {
    type: LOAD_VALIDATION_ERROR,
    error,
  };
}
