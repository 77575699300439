/**
 * AppRouter
 *
 * Route to app pages
 *
 * TODO: Secure the routes
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import ReportsPortalAuditPage from './../reports-portal/pages/ReportsPortalAuditPage';
import ReportsPortalUploadPage from './../reports-portal/pages/ReportsPortalUploadPage';
import ReportForCompletenessPage from './../reports-portal/pages/ReportForCompletenessPage';
import InventoryConfigPage from './../reports-portal/pages/InventoryConfigPage';
import ClosingMonthsPage from './../reports-portal/pages/ClosingMonthsPage';
import ReportRedirectPage from './../reports-portal/pages/ReportRedirectPage';
import NotFound from './../shared/pages/NotFoundPage/NotFoundPage';

/** AppRouter component */
const ReportsRouter = ({ checked, accessToken }) =>
  (checked ? (
    <Switch>
      <Route
        exact
        path="/reports-portal"
        key="reports-audit"
        render={({ ...props }) => <ReportsPortalAuditPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/reports-portal/audit"
        key="reports-audit"
        render={({ ...props }) => <ReportsPortalAuditPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/reports-portal/inventory/ou-configuration"
        key="reports-inventory-config"
        render={({ ...props }) => <InventoryConfigPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/reports-portal/report-for-completeness"
        key="report-for-completeness"
        render={({ ...props }) =>
          (<ReportForCompletenessPage
            {...props}
            accessToken={accessToken}
          />)
        }
      />
      <Route
        exact
        path="/reports-portal/inventory/closing-months"
        key="reports-upload"
        render={({ ...props }) => <ClosingMonthsPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/reports-portal/redirect"
        key="route-report-redirect"
        accessToken={accessToken}
        render={({ ...props }) => <ReportRedirectPage {...props} accessToken={accessToken} />}
      />
      <Route
        exact
        path="/reports-portal/:report"
        key="reports-upload"
        render={({ ...props }) => <ReportsPortalUploadPage {...props} accessToken={accessToken} />}
      />
      <Route component={NotFound} name="not-found" />
    </Switch>
  ) : null);

ReportsRouter.propTypes = {
  checked: PropTypes.bool.isRequired,
  authenticated: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  scrollContainer: PropTypes.object, // eslint-disable-line
  accessToken: PropTypes.string, // eslint-disable-line
};

ReportsRouter.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default ReportsRouter;
