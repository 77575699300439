/**
 * LocationDetail Sagas
 *
 * Gets the location from the api
 */
import { call, put, takeLatest } from 'redux-saga/effects';

import { LOAD_REPORTS } from './constants';
import { reportsLoaded, reportsLoadedError } from './actions';

import request from './../../../utils/request';

/**
 * location data request/response handler
 */
export function* getReportsData({
  id, sub, status, startDate, endDate, report, ouid, journalId, token,
}) {
  let requestURL = `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${id ||
    'ALL'}/reports?max_results=1000`;

  const query = {};

  if (sub) {
    query['location.subsidiary'] = sub;
  }
  if (report && report !== 'All') {
    query.type = encodeURIComponent(report);
  }
  if (ouid) {
    query['location.operating_unit.id'] = ouid;
  }
  if (status && status !== 'All') {
    query['audit.status'] = status;
  }
  if (startDate && endDate) {
    // effective_date is a date represented as a string (YYYY-MM-DD)
    query.effective_date = { $gte: startDate.slice(0, 10), $lte: endDate.slice(0, 10) };
  }
  if (journalId) {
    query.descriptors = { $elemMatch: { name: 'blackline_journal_id', value: journalId } };
  }
  requestURL = `${requestURL}&where=${JSON.stringify(query)}`;

  const options = {
    method: 'GET',
  };
  options.headers = {
    Authorization: `Bearer ${token}`, // eslint-disable-line
    'cache-control': 'no-cache',
  };
  try {
    const data = yield call(request, requestURL, options);
    yield put(reportsLoaded(data));
  } catch (err) {
    yield put(reportsLoadedError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* locationData() {
  // get the latest api call
  yield takeLatest(LOAD_REPORTS, getReportsData);
}
