// MAIN REACT STUFF
import '@babel/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';

// import Immutable from 'immutable';
// import installDevTools from 'immutable-devtools';
import 'sanitize.css/sanitize.css';
import 'antd/dist/antd.css';

// POLYFILLS
import './utils/polyfills';

import './global-styles';

import history from './utils/history';
import configureStore from './configureStore';
import App from './App/index';

// installDevTools(Immutable);
// import registerServiceWorker from './registerServiceWorker';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');

const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  module.hot.accept(['./App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// Sentry Raven
if (
  typeof window !== 'undefined' &&
  typeof window.Raven !== 'undefined' &&
  !(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1')
) {
  console.log(`-- init raven (${process.env.REACT_APP_ENV})`); // eslint-disable-line
  window.Raven.config('https://f474b9b1855b4c7da83b7ae35e0ab936@sentry.io/291834', {
    environment: process.env.REACT_APP_ENV,
  }).install();
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
