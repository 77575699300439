/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Layout, Modal, Button } from 'antd';
import { createStructuredSelector } from 'reselect';
import bowser from 'bowser';
import { stringify } from 'qs';
import { Redirect, withRouter, Switch, Route } from 'react-router-dom';
import ImmutablePropTypes from 'react-immutable-proptypes';
import IdleTimer from 'react-idle-timer';

// INTERNALS
import injectSaga from './../utils/injectSaga';
import { setSentryUser } from './../utils/sentry';
import LoadingIndicator from './../shared/components/LoadingIndicator';
import EDEApp from './edeApp';
import LandingApp from './landingApp';
import ReportsApp from './reportsApp';
import LoginPage from './../shared/pages/LoginPage/Loadable';
import LegacyPage from './../shared/pages/LegacyPage';
import NotFound from './../shared/pages/NotFoundPage/NotFoundPage';

// import { version } from './../../package.json';

// ACTIONS
import {
  loadLocations,
  loadAuth,
  authLogout,
  loadValidation,
  loadMeta,
  sessionTimeout as sessionTimeoutAction,
  resetSessionTimeout,
} from './actions';

// SELECTORS
import {
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectLocation,
  makeSelectSessionAuthenticated,
  makeSelectSessionChecked,
  makeSelectSessionUserEmail,
  makeSelectSessionUser,
  makeSelectSessionUserEntitlements,
  makeSelectSessionTimeout,
} from './selectors';

// SAGAS
import saga from './sagas';

// STYLES
import { AppWrapper, StyledLayout } from './styles';

/**
 * App container componentent
 */
export class App extends React.Component {
  constructor(props) {
    super(props);
    this.scrollContainer = null;
    this.sentryUserSet = false;
    this.state = {
      collapsed: false,
    };
  }

  componentDidMount() {
    const {
      checked, authenticated, session, entitlements, user,
    } = this.props;
    if (checked && !authenticated) {
      this.fetchData();
    } else if (checked && authenticated && session.size && !this.sentryUserSet) {
      const accessToken = session.get('access_token');
      this.fetchData(accessToken, entitlements);
      if (user && typeof window !== 'undefined') {
        console.info('set email mount', user);
        setSentryUser(user);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      checked, authenticated, session, entitlements, user,
    } = this.props;
    if (!prevProps.checked && checked && !authenticated) {
      this.fetchData();
    } else if (checked && authenticated && session.size && !this.sentryUserSet) {
      this.sentryUserSet = true;
      const accessToken = session.get('access_token');
      this.fetchData(accessToken, entitlements);
      if (user && typeof window !== 'undefined') {
        console.info('set email update', user);
        setSentryUser(user);
      }
    }
  }

  /** menu collapse action */
  onCollapse = () => this.setState({ collapsed: !this.state.collapsed });

  onIdle = () => {
    // if user has been inactive for more than 15 minutes, logout and redirect to login page
    const { onSessionTimeout, user } = this.props;
    if (user) {
      onSessionTimeout();
    }
  };

  onRelogin = () => {
    const { history, onResetSessionTimeout } = this.props;
    onResetSessionTimeout();
    history.push('/login');
  };

  fetchData = (token, entitlements) => {
    this.props.onLoadLocations(token, entitlements);
    this.props.onLoadValidation(token, entitlements);
    this.props.onLoadMeta(token, entitlements);
  };

  /** Render App */
  render() {
    const {
      location,
      checked,
      authenticated,
      locationsError,
      locations,
      session,
      // entitlements,
      // onLogout,
      sessionTimeout,
    } = this.props;

    // redirect to login when user does not have access
    // add redirect url to login parmas to send to the prior page upon successful login
    if (
      locationsError &&
      locationsError.response &&
      (locationsError.response.status === 403 || locationsError.response.status === 401) &&
      location.pathname.indexOf('login') < 0 &&
      location.pathname.indexOf('legacy') < 0
    ) {
      window.location = `/login?${stringify({
        redirect: (location.pathname + location.search).substr(1),
      })}`;
      return null;
    }

    // redirect if legacy value
    if (
      !bowser.check({
        msie: '11',
        chrome: '64',
        firefox: '52',
        safari: '11',
      }) &&
      location.pathname.indexOf('legacy') < 0
    ) {
      return (
        <Redirect
          to={{
            pathname: '/legacy',
          }}
        />
      );
    }
    return (
      <AppWrapper>
        <Modal
          title="Session Expired"
          visible={sessionTimeout}
          closable={false}
          footer={
            <Button type="primary" onClick={this.onRelogin}>
              Log in
            </Button>
          }
        >
          <p>
            Your session has expired due to inactivity or has exceeded the 60 minute limit. Please
            log in to continue.
          </p>
        </Modal>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 15}
        >
          <StyledLayout>
            <Helmet
              titleTemplate="%s - Delaware North Data Explorer"
              defaultTitle="Delaware North Data Explorer"
            >
              <meta name="description" content="Delaware North Data Explorer" />
            </Helmet>

            {locations.size ||
            location.pathname.includes('login') ||
            location.pathname.includes('legacy') ? (
              <Layout>
                <Switch>
                  <Route
                    exact
                    path="/"
                    key="route-landing"
                    render={({ ...props }) => (
                      <LandingApp
                        {...props}
                        checked={checked}
                        authenticated={authenticated}
                        accessToken={session.get('access_token')}
                        location={location}
                        scrollContainer={this.scrollContainer}
                      />
                    )}
                  />
                  <Route
                    path="/explorer"
                    key="route-ede"
                    render={({ ...props }) => (
                      <EDEApp
                        {...props}
                        checked={checked}
                        authenticated={authenticated}
                        accessToken={session.get('access_token')}
                        location={location}
                        scrollContainer={this.scrollContainer}
                      />
                    )}
                  />
                  <Route
                    path="/reports-portal"
                    key="route-reports"
                    render={({ ...props }) => (
                      <ReportsApp
                        {...props}
                        checked={checked}
                        authenticated={authenticated}
                        accessToken={session.get('access_token')}
                        location={location}
                        scrollContainer={this.scrollContainer}
                      />
                    )}
                  />
                  <Route path="/legacy" component={LegacyPage} key="legacy" />
                  <Route path="/login" component={LoginPage} key="route-login" />
                  <Route component={NotFound} name="not-found" />
                </Switch>
              </Layout>
            ) : (
              <Layout>
                <LoadingIndicator />
              </Layout>
            )}
          </StyledLayout>
        </IdleTimer>
      </AppWrapper>
    );
  }
}

App.propTypes = {
  onLogout: PropTypes.func,
  onLoadLocations: PropTypes.func,
  onSessionTimeout: PropTypes.func,
  onResetSessionTimeout: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  user: PropTypes.string,
  session: ImmutablePropTypes.map, // eslint-disable-line
  locationsError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]), // eslint-disable-line
  locations: ImmutablePropTypes.map, // eslint-disable-line
  onLoadValidation: PropTypes.func,
  onLoadMeta: PropTypes.func,
  entitlements: PropTypes.object, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  sessionTimeout: PropTypes.bool,
};

App.defaultProps = {
  onLogout: () => {
    console.warn('onLogout not defined in App Component'); // eslint-disable-line
  },
  onLoadLocations: () => {
    console.warn('onLoadLocations not defined in App Component'); // eslint-disable-line
  },
  onLoadValidation: () => {
    console.warn('onLoadValidation not defined in App Component'); // eslint-disable-line
  },
  onLoadMeta: () => {
    console.warn('onLoadMeta not defined in App Component'); // eslint-disable-line
  },
  onSessionTimeout: () => {
    console.warn('onSessionTimeout not defined in App Component'); // eslint-disable-line
  },
  onResetSessionTimeout: () => {
    console.warn('onResetSessionTimeout not defined in App Component'); // eslint-disable-line
  },
  location: {
    pathname: '',
  },
  checked: false,
  authenticated: false,
  user: null,
  session: null,
  sessionTimeout: false,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLoadAuth: () => {
      dispatch(loadAuth());
    },
    onLogout: (history) => {
      dispatch(authLogout(history));
    },
    onLoadLocations: (token, entitlements) => {
      dispatch(loadLocations(token, entitlements));
    },
    onLoadValidation: (token, entitlements) => {
      dispatch(loadValidation(token, entitlements));
    },
    onLoadMeta: (token, entitlements) => {
      dispatch(loadMeta(token, entitlements));
    },
    onSessionTimeout: () => {
      dispatch(sessionTimeoutAction());
    },
    onResetSessionTimeout: () => {
      dispatch(resetSessionTimeout());
    },
  };
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  locations: makeSelectLocations(),
  locationsError: makeSelectLocationsError(),
  authenticated: makeSelectSessionAuthenticated(),
  checked: makeSelectSessionChecked(),
  user: makeSelectSessionUserEmail(),
  session: makeSelectSessionUser(),
  entitlements: makeSelectSessionUserEntitlements(),
  sessionTimeout: makeSelectSessionTimeout(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withSaga = injectSaga({ key: 'app', saga });

export default withRouter(
  compose(
    withSaga,
    withConnect,
  )(App),
);
