/*
 * DateFilter
 *
 * Date inputs for data filters
 * - filter by range with range prop
 * - filter by day with day prop
 */

import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const { RangePicker } = DatePicker;

const DateFilter = ({
  dateFilterAction, range, day, defaultDate, value,
}) => {
  function onChangeRange(dates) {
    dateFilterAction(dates[0], dates[1]);
  }

  /** Filter action */
  function onChangeDate(date) {
    dateFilterAction(date);
  }

  return (
    <div>
      {range && (
        <RangePicker
          ranges={{
            Today: [moment(), moment()],
            Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          defaultValue={
            defaultDate
              ? [moment(defaultDate[0]), moment(defaultDate[1])]
              : [moment().subtract(1, 'day'), moment().subtract(1, 'day')]
          }
          onChange={onChangeRange}
        />
      )}
      {day && (
        <DatePicker
          defaultValue={defaultDate ? moment(defaultDate) : moment().subtract(1, 'day')}
          onChange={onChangeDate}
          value={value}
        />
      )}
    </div>
  );
};

DateFilter.propTypes = {
  dateFilterAction: PropTypes.func,
  range: PropTypes.bool,
  day: PropTypes.bool,
  defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  value: PropTypes.object, // eslint-disable-line
};

DateFilter.defaultProps = {
  dateFilterAction: () => console.warn('no date filter action'), // eslint-disable-line
  range: false,
  day: false,
  defaultDate: null,
};

export default DateFilter;
