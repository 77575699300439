/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Button,
  Alert,
  Card,
  Icon,
  Form,
  Upload,
  DatePicker,
  Select,
  Input,
  Modal,
  Spin,
  message,
} from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import CopyToClipboard from 'react-copy-to-clipboard';

// INTERNAL
import PageHeader from './../../../shared/components/PageHeader/index';
import { reportLocations, getReportTypes } from '../../utils/reportTypes';
import { captureException } from '../../../utils/sentry';

// SELECTORS
import {
  makeSelectValidation,
  makeSelectLocations,
  makeSelectLocationsLoading,
  makeSelectSessionChecked,
  makeSelectSessionAuthenticated,
  makeSelectSessionUserEntitlements,
  makeSelectSessionUser,
  makeSelectMeta,
} from './../../../App/selectors';

// STYLES
import { Section, StyledContent } from './../../../global-styles';

const FormItem = Form.Item;

class ReportsPortalUploadPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
      uploading: false,
      subsidiaryFilter: null,
      selectedLocation: null,
    };
    this.reportingLocations = fromJS([]);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.location.pathname && // eslint-disable-line
      prevProps.location.pathname && // eslint-disable-line
      prevProps.location.pathname !== this.props.location.pathname // eslint-disable-line
    ) {
      this.handleReset();
    }
  }

  onSubChange = (e) => {
    const id = e.split('|')[0];
    if (id.toLowerCase() !== 'all') {
      this.setState({ subsidiaryFilter: id });
    } else {
      this.setState({ subsidiaryFilter: null });
    }
    this.props.form.setFields({
      location: {
        value: undefined,
      },
    });
  };

  getSourceSystem = (key) => {
    switch (key) {
      case 'order-to-cash':
        return 'order_to_cash';
      case 'revenue-integration':
        return 'revenue_integration';
      case 'reporting':
        return 'general_reporting';
      case 'blackline':
        return 'blackline_journal';
      case 'inventory':
        return 'inventory_audit';
      default:
        return '';
    }
  };

  getDescriptors = (key, values) => {
    switch (key) {
      case 'blackline':
        return [{ name: 'blackline_journal_id', value: values.blacklineJournalId }];
      case 'inventory':
        return [{ name: 'period', value: values.month.format('YYYYMM') }];
      default:
        return [];
    }
  };

  getRecord = (id) => {
    const { session } = this.props;
    return fetch(`${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/ALL/reports/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Report error getting record');
    });
  };

  removeRecord = (id, locationId) => {
    const { session } = this.props;
    return fetch(`${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${locationId}/reports/${id}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        Accept: 'application/json',
      },
    }).then((response) => {
      if (response.ok) {
        return true; // No payload to return from a 204
      }
      throw Error('Report error deleting record');
    });
  };

  isMonthClosed = async () => {
    const { form, session } = this.props;
    const month = form.getFieldValue('month');
    const location = form.getFieldValue('location');

    if (!month || !location || !moment.isMoment(month)) {
      return false;
    }

    const locationId = location.split('|')[2];
    const ouId = location.split('|')[0];
    const closeId = `${locationId}+${ouId}+${month.format('YYYYMM')}`;

    // All months outside of the current sliding window are closed whether a record exists or not.
    // All months inside the current window are open by default.
    const selectedReportingWindow = Math.floor(month.diff(moment().startOf('month'), 'month', true));
    if (selectedReportingWindow < (process.env.SLIDING_WINDOW_LENGTH || -12)) {
      return new Promise(((resolve) => {
        resolve(true);
      }));
    }

    return fetch(`${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/inventorydepot/closed/${closeId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        Accept: 'application/json',
      },
    }).then((res) => {
      if (res.status && res.status === '401') {
        throw new Error(
          'Permission Denied: Make sure you are logged in and refresh your browser.',
        );
      }
      return res;
    }).then(res => res.json())
      .then(res => ('closed' in res && res.closed));
  };

  validateMonth = (rule, value, cb) => {
    this.isMonthClosed().then(closedStatus => (closedStatus ? cb(true) : cb()));
  };

  showResultsModal = (successfulUploads, failedUploads) => {
    Modal.info({
      title: 'Upload Results',
      content: (
        <div>
          {successfulUploads && successfulUploads.length ? (
            <div style={{ color: 'rgb(82, 196, 26)' }}>
              Successful Uploads: (Click to copy file URL)
            </div>
          ) : null}

          {successfulUploads &&
            successfulUploads.map(file => (
              <div key={file.name}>
                <b>{file.name}</b>
                <ul>
                  <CopyToClipboard text={file.href} onCopy={() => message.success('Copied')}>
                    <li>
                      <a>Temporary Link</a>
                    </li>
                  </CopyToClipboard>
                  <CopyToClipboard text={file.file} onCopy={() => message.success('Copied')}>
                    <li>
                      <a>Permanent Link</a>
                    </li>
                  </CopyToClipboard>
                </ul>
              </div>
            ))}

          {failedUploads && failedUploads.length ? (
            <div style={{ color: 'rgb(245, 34, 45)' }}>Failed Uploads:</div>
          ) : null}
          <ul>
            {failedUploads &&
              failedUploads.map(failure => (
                <li key={failure.file}>
                  <b>{failure.file}</b> - {failure.error}
                </li>
              ))}
          </ul>
        </div>
      ),
      onOk: () => {
        this.handleReset();
      },
    });
  };

  createReportRecord = (body) => {
    const { session } = this.props;
    return fetch(
      `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${body.location._id}/reports`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${session.get('access_token')}`,
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      },
    ).then(response => response.json());
  };

  uploadReportFile = (res, file) => {
    const { url, fields } = res.upload;
    const data = new FormData(); // eslint-disable-line
    data.append('policy', fields.policy);
    data.append('AWSAccessKeyId', fields.AWSAccessKeyId);
    data.append('key', fields.key);
    data.append('signature', fields.signature);
    data.append('x-amz-security-token', fields['x-amz-security-token']);
    data.append('file', file);
    // post file to s3
    return fetch(url, {
      method: 'POST',
      body: data,
      headers: {
        'x-amz-server-side-encryption': 'AES256',
        'Cache-Control': 'no-cache',
      },
    }).then((response) => {
      if (!response.ok) {
        throw Error('Report upload to s3 failed');
      }
      return response;
    });
  };

  updateRecord = (res, user, id, locationId) => {
    const { session } = this.props;
    const audit = res.audit ? res.audit : {};
    // update logs
    const newLog = {
      comments: '',
      date: this.edcDateUTC(Date.now()),
      status: 'uploaded',
      user,
    };

    const auditLog = audit.log ? audit.log : [];
    auditLog.push(newLog);

    const updates = {
      location: {
        _id: locationId,
      },
      audit: {
        comments: '',
        date: this.edcDateUTC(Date.now()),
        status: 'uploaded',
        user,
        log: auditLog,
      },
    };

    return fetch(`${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${locationId}/reports/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(updates),
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.json());
  };

  edcDateUTC = (d) => {
    // EDC API cannot take fractional seconds per ISO standards
    // Must look like 2018-02-24T00:00:00Z
    const edcFormat = 'YYYY-MM-DDTHH:mm:ss';
    return `${moment(d)
      .utc()
      .format(edcFormat)}Z`;
  };

  delay = t => new Promise(resolve => setTimeout(resolve, t));

  handleSubmit = (e) => {
    e.preventDefault();
    const { match } = this.props;
    this.props.form.validateFields((err, values) => {
      const successfulUploads = [];
      const failedUploads = [];
      let completedUploads = 0;

      if (!err) {
        this.setState({ uploading: true });
        const isoDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';
        const isoDateFormat = 'YYYY-MM-DD';
        const dateNow = this.edcDateUTC(Date.now());
        // loop through files in form and upload to reports endpoint
        this.state.fileList.map((file) => {
          // These must be inside the for loop because each value is specific to the file
          // being uploaded. If set the scope of these variables to be outside the loop,
          // it will set the values to the values from the last uploaded file
          let reportId = null;
          let reportHref = null;
          let fileLink = null;

          // assemble record
          const body = {
            'location._id': values.location.split('|')[2],
            location: {
              _id: values.location.split('|')[2],
            },
            'location.operating_unit.id': values.location.split('|')[0],
            'location.operating_unit.name': values.location.split('|')[1],
            effective_date: `${values.month.format(isoDateFormat)}`,
            extract_time: dateNow,
            start_time: `${values.month.format(isoDateTimeFormat)}Z`,
            end_time: `${values.month
              .clone()
              .add(1, 'months')
              .format(isoDateTimeFormat)}Z`,
            frequency: 'period',
            descriptors: this.getDescriptors(match.params.report, values),
            source_system: this.getSourceSystem(match.params.report),
            type: `${match.params.report !== 'blackline' ? values.report : 'Journal Backup'}`,
            'audit.user': values.user,
            'audit.date': dateNow,
            'audit.comments': '',
            'audit.status': 'upload_pending',
            'audit.log.user': values.user,
            'audit.log.status': 'upload_pending',
            'audit.log.date': dateNow,
            'audit.log.comments': '',
            report: file.name,
          };

          this.createReportRecord(body)
            .then((res) => {
              if (res._status !== 'OK') {
                if (res._error && res._error.message) {
                  throw new Error(res._error.message);
                } else {
                  if (res.status && res.status === '401') {
                    throw new Error(
                      'Permission Denied: You do not have permissions to upload this report. Make sure you are logged in and refresh your browser.',
                    );
                  }
                  throw new Error('Error creating report record');
                }
              }
              reportId = res._id;
              return this.uploadReportFile(res, file);
            })
            // Delay to make sure data has been written to database,
            // sometimes data is not available otherwise
            .then(() => this.delay(3000))
            // Get newly created record
            .then(() => this.getRecord(reportId))
            // update report from pending_upload to uploaded
            .then((res) => {
              reportHref = res.report.href;
              fileLink = res.report.file;
              return this.updateRecord(res, values.user, reportId, body['location._id']);
            })
            // handle responses
            .then((res) => {
              if (res._status !== 'OK') {
                if (res._error && res._error.message) {
                  throw new Error(res._error.message);
                } else {
                  if (res.status && res.status === '401') {
                    throw new Error(
                      'Permission Denied: You do not have permissions to upload this report. Make sure you are logged in and refresh your browser.',
                    );
                  }
                  throw new Error('Report update failed');
                }
              }
              successfulUploads.push({
                name: file.name,
                href: reportHref,
                file: fileLink,
              });
              completedUploads += 1;
              reportId = null;
              if (completedUploads >= this.state.fileList.length) {
                this.setState({ uploading: false, subsidiaryFilter: null });
                this.showResultsModal(successfulUploads, failedUploads);
              }
            })
            .catch((error) => {
              console.error(error); // eslint-disable-line
              captureException(error);
              // this.setState({ uploading: false });
              failedUploads.push({ file: file.name, error: error.toString() });
              completedUploads += 1;
              if (reportId) {
                this.removeRecord(reportId, body['location._id']);
              }
              reportId = null;
              if (completedUploads >= this.state.fileList.length) {
                this.setState({ uploading: false, subsidiaryFilter: null });
                this.showResultsModal(successfulUploads, failedUploads);
              }
            });
          return null;
        });
      }
    });
  };

  handleReset = () => {
    this.setState({ fileList: [] });
    this.props.form.resetFields();
  };

  formatDate = (key) => {
    switch (key) {
      case 'reporting':
        return 'Period End Date';
      case 'order-to-cash':
        return 'Sales Date';
      case 'revenue-integration':
        return 'Sales Date';
      case 'blackline':
        return 'Journal Date';
      case 'inventory':
        return 'Fiscal Month';
      default:
        return '';
    }
  };

  formatName = (key) => {
    switch (key) {
      case 'reporting':
        return 'Reporting';
      case 'inventory':
        return 'Inventory Reports';
      case 'order-to-cash':
        return 'Order to Cash';
      case 'revenue-integration':
        return 'Revenue Integration';
      case 'blackline':
        return 'Blackline Journal Entry';
      default:
        return '';
    }
  };

  renderLocationSelect = () => {
    const { form, locations, match } = this.props;
    const { subsidiaryFilter } = this.state;
    const filteredLocations =
      locations
        .get('_items')
        .filter(location => reportLocations.indexOf(location.get('_id')) >= 0) || fromJS([]);
    const reportingLocationsList = [];
    filteredLocations.toJS().map((location) => {
      const operatingUnits = location.operating_units;
      if (operatingUnits) {
        const reportType =
          match.params.report === 'blackline' ? 'blackline-journal' : match.params.report;
        operatingUnits.map((unit) => {
          if (getReportTypes(reportType, unit.id).length) {
            reportingLocationsList.push({
              locId: location._id,
              locName: location.name,
              ouId: unit.id,
              ouName: unit.name,
              subsidiary: location.subsidiary,
            });
          }
          return null;
        });
      }
      return null;
    });

    const reportingLocations = fromJS(reportingLocationsList).sortBy(loc => loc.get('ouId'));

    if (subsidiaryFilter) {
      return form.getFieldDecorator('location', {
        rules: [{ required: true, message: 'Please select your location!' }],
      })(
        <Select
          showSearch
          placeholder="Please select a location"
          key="location-select"
          getPopupContainer={() => document.getElementById('layout-content')}
          onChange={(e) => {
            this.setState({ selectedLocation: e.split('|')[0] });
          }}
        >
          {reportingLocations
            .filter(location => location.get('subsidiary') === subsidiaryFilter)
            .map(loc => (
              <Select.Option
                value={`${loc.get('ouId')}|${loc.get('ouName')}|${loc.get('locId')}|${loc.get(
                  'locName',
                )}`}
                key={loc.get('ouId')}
              >
                {`${loc.get('ouId')} - ${loc.get('ouName')}`}
              </Select.Option>
            ))}
        </Select>,
      );
    }
    return form.getFieldDecorator('location', {
      rules: [{ required: true, message: 'Please select your location!' }],
    })(
      <Select
        showSearch
        placeholder="Please select a location"
        key="location-select"
        getPopupContainer={() => document.getElementById('layout-content')}
        onChange={(e) => {
          this.setState({ selectedLocation: e.split('|')[0] }, () => {
            if (form.getFieldValue('month')) { form.validateFields(['month'], { force: true }); }
          });
        }}
      >
        {reportingLocations.map(loc => (
          <Select.Option
            value={`${loc.get('ouId')}|${loc.get('ouName')}|${loc.get('locId')}|${loc.get(
              'locName',
            )}`}
            key={loc.get('ouId')}
          >
            {`${loc.get('ouId')} - ${loc.get('ouName')}`}
          </Select.Option>
        ))}
      </Select>,
    );
  };

  render() {
    const {
      checked, authenticated, locationsLoading, form, session, meta, match,
    } = this.props;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const spinIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    const subsidiaries = meta.get('subsidiaries') ? meta.get('subsidiaries').toJS() : [];
    return (
      <StyledContent>
        <Helmet>
          <title>Reports Portal</title>
          <meta name="description" content="Enterprise Data Explorer Reports Portal" />
        </Helmet>
        <PageHeader title="Reports">
          <h1>{this.formatName(match.params.report)}</h1>
        </PageHeader>
        <Section>
          <Card loading={!!locationsLoading}>
            {checked && !authenticated ? (
              <Alert
                message="You must login to access the reports portal."
                description=""
                type="error"
              />
            ) : (
              <Form onSubmit={this.handleSubmit}>
                <FormItem {...formItemLayout} label="Subsidiary" key="subsidiary">
                  {form.getFieldDecorator('subsidiary', {
                    rules: [
                      {
                        required: false,
                        message: 'Please select your subsidiary!',
                      },
                    ],
                    initialValue: 'All',
                    onChange: this.onSubChange,
                  })(
                    <Select
                      showSearch
                      placeholder="Please select a location"
                      key="location-select"
                      getPopupContainer={() => document.getElementById('layout-content')}
                    >
                      <Select.Option value="All" key="All">
                        All
                      </Select.Option>
                      {subsidiaries &&
                        subsidiaries.map((sub) => {
                          if (sub._id !== 'PMAX' && sub._id !== '-') {
                            return (
                              <Select.Option
                                value={`${sub._id}|${sub.business_unit}|${sub.name}`}
                                key={sub._id}
                              >
                                {sub.business_unit} - {sub.name}
                              </Select.Option>
                            );
                          }
                          return null;
                        })}
                    </Select>,
                  )}
                </FormItem>
                <FormItem {...formItemLayout} label="Operating Unit" hasFeedback key="location">
                  {this.renderLocationSelect()}
                </FormItem>
                <FormItem {...formItemLayout} label="User" hasFeedback key="user">
                  {form.getFieldDecorator('user', {
                    rules: [{ required: true }],
                    initialValue: session.get('email'),
                  })(<Input disabled />)}
                </FormItem>
                {match.params.report === 'blackline' ? (
                  <FormItem
                    {...formItemLayout}
                    label="Blackline Journal ID"
                    hasFeedback
                    key="blacklineJournalId"
                  >
                    {form.getFieldDecorator('blacklineJournalId', {
                      rules: [{ required: true }],
                    })(<Input placeholder="Blackline Journal Id" />)}
                  </FormItem>
                ) : null}
                {match.params.report === 'inventory' ? (
                  <FormItem {...formItemLayout} label="Fiscal Month" hasFeedback key="month">
                    {form.getFieldDecorator('month', {
                      rules: [
                        {
                          type: 'object',
                          required: true,
                          message: 'Please select fiscal month!',
                        },
                        {
                          message: 'The location has already closed the month for the selected date.',
                          validator: this.validateMonth,
                        },
                      ],
                    })(<DatePicker.MonthPicker />)}
                  </FormItem>
                ) : (
                  <FormItem
                    {...formItemLayout}
                    label={this.formatDate(match.params.report)}
                    hasFeedback
                    key="month"
                  >
                    {form.getFieldDecorator('month', {
                      rules: [
                        {
                          type: 'object',
                          required: true,
                          message: 'Please select fiscal month!',
                        },
                      ],
                    })(<DatePicker />)}
                  </FormItem>
                )}
                {match.params.report !== 'blackline' ? (
                  <FormItem {...formItemLayout} label="Report Type" hasFeedback key="report">
                    {form.getFieldDecorator('report', {
                      rules: [
                        {
                          required: true,
                          message: 'Please select report type!',
                        },
                      ],
                    })(
                      <Select
                        showSearch
                        placeholder="Please select a report type"
                        key="report-select"
                      >
                        {getReportTypes(match.params.report, this.state.selectedLocation).map(
                          report => (
                            <Select.Option value={report} key={report}>
                              {report}
                            </Select.Option>
                          ),
                        )}
                      </Select>,
                    )}
                  </FormItem>
                ) : null}
                <FormItem {...formItemLayout} label="Upload" hasFeedback key="upload">
                  <div className="dropbox">
                    {form.getFieldDecorator('files', {
                      rules: [{ required: true, message: 'Please select a file!' }],
                    })(
                      <Upload.Dragger
                        name="files"
                        multiple
                        action=""
                        beforeUpload={(file) => {
                          this.setState(({ fileList }) => ({
                            fileList: [...fileList, file],
                          }));
                          return false;
                        }}
                        onRemove={(file) => {
                          this.setState(({ fileList }) => {
                            const index = fileList.indexOf(file);
                            const newFileList = fileList.slice();
                            newFileList.splice(index, 1);
                            return {
                              fileList: newFileList,
                            };
                          });
                        }}
                        fileList={this.state.fileList}
                      >
                        <p className="ant-upload-drag-icon">
                          <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">
                          Click or drag files to this area to upload
                        </p>
                      </Upload.Dragger>,
                    )}
                  </div>
                </FormItem>
                {this.state.uploading ? (
                  <Spin indicator={spinIcon} />
                ) : (
                  <FormItem wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </FormItem>
                )}
              </Form>
            )}
          </Card>
        </Section>
      </StyledContent>
    );
  }
}

ReportsPortalUploadPage.propTypes = {
  onLoadReports: PropTypes.func,
  locationsLoading: PropTypes.bool,
  reportsLoading: PropTypes.bool,
  locations: ImmutablePropTypes.map, // eslint-disable-line
  session: ImmutablePropTypes.map, // eslint-disable-line
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
  form: PropTypes.object, // eslint-disable-line
  meta: ImmutablePropTypes.map, // eslint-disable-line
  match: PropTypes.object, // eslint-disable-line
};

ReportsPortalUploadPage.defaultProps = {
  onLoadReports: () => console.warn('onLoadReports is not defined'), // eslint-disable-line
  locationsLoading: false,
  reportsLoading: false,
  locations: fromJS({}),
  checked: false,
  authenticated: false,
  form: {},
};

const mapStateToProps = createStructuredSelector({
  locations: makeSelectLocations(),
  locationsLoading: makeSelectLocationsLoading(),
  validation: makeSelectValidation(),
  checked: makeSelectSessionChecked(),
  session: makeSelectSessionUser(),
  authenticated: makeSelectSessionAuthenticated(),
  entitlements: makeSelectSessionUserEntitlements(),
  meta: makeSelectMeta(),
});

const WrappedReportsPortalUploadPage = Form.create()(ReportsPortalUploadPage);

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(WrappedReportsPortalUploadPage);
