/**
 *
 * ReportsPortalAudit Selectors
 *
 * The reports state selectors
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectReports = state => state.get('reportsAudit', initialState);

const makeSelectReportsLoading = () =>
  createSelector(
    selectReports,
    reportsState => reportsState.get('loading'),
  );

const makeSelectReports = () =>
  createSelector(
    selectReports,
    reportsState => reportsState.get('reportsData'),
  );

export { selectReports, makeSelectReports, makeSelectReportsLoading };
