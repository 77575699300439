import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  Button,
  Alert,
  Card,
  Dropdown,
  Input,
  Menu,
  Icon,
  Form,
  DatePicker,
  Select,
  Spin,
  Timeline,
  Modal,
  message,
} from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { stringify, parse } from 'qs';
import moment from 'moment';
import CopyToClipboard from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

// INTERNAL
import injectSaga from './../../../utils/injectSaga';
import injectReducer from './../../../utils/injectReducer';
import PageHeader from './../../../shared/components/PageHeader/index';
import UpdateReportModal from './updateReportModal';
import { reportLocations, getReportTypes } from '../../utils/reportTypes';

// ACTIONS
import { loadReports } from './actions';

// REDUCERS
import reducer from './reducer';

// SAGA
import saga from './sagas';

// SELECTORS
import {
  makeSelectValidation,
  makeSelectLocations,
  makeSelectLocationsLoading,
  makeSelectSessionChecked,
  makeSelectSessionAuthenticated,
  makeSelectSessionUserEntitlements,
  makeSelectSessionUser,
  makeSelectMeta,
} from './../../../App/selectors';
import { makeSelectReports, makeSelectReportsLoading } from './selectors';

// STYLES
import { Section, StyledTable, StyledContent } from './../../../global-styles';

const FormItem = Form.Item;
class ReportsPortalAuditPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      updating: false,
      updatingRecord: null,
      subsidiaryFilter: null,
      selectedLocation: null,
    };
  }

  onSubChange = (e) => {
    const id = e.split('|')[0];
    if (id.toLowerCase() !== 'all') {
      this.setState({ subsidiaryFilter: id });
    } else {
      this.setState({ subsidiaryFilter: null });
    }
    this.props.form.setFields({
      location: {
        value: undefined,
      },
    });
  };

  getRecord = (loc, id) => {
    const { session } = this.props;
    return fetch(`${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${loc}/reports/${id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Report error getting record');
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      checked, authenticated, session, history,
    } = this.props;
    if (checked && authenticated) {
      this.props.form.validateFields((err, values) => {
        if (!err) {
          const accessToken = session.get('access_token');
          const dateformat = 'YYYY-MM-DDTHH:mm:ss';
          history.push({
            search: stringify({
              values: {
                location: values.location === 'All' ? null : values.location.split('|')[2],
                ouid: values.location === 'All' ? null : values.location.split('|')[0],
                date: [
                  `${values.date[0].format(dateformat)}Z`,
                  `${values.date[1].format(dateformat)}Z`,
                ],
                report: values.report === 'All' ? null : values.report,
              },
            }),
          });
          this.props.onLoadReports(
            values.location === 'All' ? null : values.location.split('|')[2],
            null,
            values.status === 'All' ? null : values.status,
            values.date[0] ? `${values.date[0].format(dateformat)}Z` : null,
            values.date[1] ? `${values.date[1].format(dateformat)}Z` : null,
            values.report === 'All' ? null : values.report,
            values.location === 'All' ? null : values.location.split('|')[0],
            values.blacklineJournalId ? values.blacklineJournalId : null,
            accessToken,
          );
        }
      });
    }
  };

  handleReload = () => {
    const {
      checked, authenticated, location, session,
    } = this.props;
    if (checked && authenticated) {
      const accessToken = session.get('access_token');
      const { values } = parse(location.search.substr(1));
      this.props.onLoadReports(
        values.location,
        null,
        values.status,
        values.date[0],
        values.date[1],
        values.report,
        values.ouid,
        values.blacklineJournalId,
        accessToken,
      );
    }
  };

  delay = t => new Promise(resolve => setTimeout(resolve, t));

  handleMenuClick = (e, record) => {
    switch (e.key) {
      case 'copy':
      case 'download': {
        message.loading('Requesting report download URL...', 0);

        this.getRecord(record.location._id, record._id).then((res) => {
          this.delay(1500).then(() => {
            message.destroy();
            switch (e.key) {
              case 'copy': {
                message.success('The Temporary URL has been copied to your clipboard.');
                copy(res.report.href);
                break;
              }
              case 'download': {
                window.open(res.report.href);
                break;
              }
              default: {
                break;
              }
            }
          });
        });
        break;
      }
      case 'update': {
        this.toggleUpdateModal(record);
        break;
      }
      default: {
        break;
      }
    }
  };

  edcDateUTC = (d) => {
    // EDC API cannot take fractional seconds per ISO standards
    // Must look like 2018-02-24T00:00:00Z
    const edcFormat = 'YYYY-MM-DDTHH:mm:ss';
    return `${moment(d)
      .utc()
      .format(edcFormat)}Z`;
  };

  updateReport = (err, values) => {
    const { session } = this.props;
    const { updatingRecord } = this.state;

    const auditLog = updatingRecord.audit.log;

    const newLog = {
      comments: values.comments,
      date: this.edcDateUTC(Date.now()),
      status: values.status,
      user: values.user,
    };

    auditLog.push(newLog);

    const updates = {
      audit: {
        comments: values.comments,
        date: this.edcDateUTC(Date.now()),
        status: values.status,
        user: values.user,
        log: auditLog,
      },
      location: {
        _id: updatingRecord.location._id,
      },
    };

    fetch(
      `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/${updatingRecord.location._id}/reports/${
        updatingRecord._id
      }`,
      {
        method: 'PATCH',
        body: JSON.stringify(updates),
        headers: {
          Authorization: `Bearer ${session.get('access_token')}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'cache-control': 'no-cache',
        },
      },
    )
      .then(response => response.json())
      .then((res) => {
        if (res._error) {
          throw new Error('Report Failed');
        }
        this.handleReload();
        this.setState({
          updating: false,
          updatingRecord: null,
        });
      })
      .catch(() => {
        Modal.error({
          title: 'Error',
          content: 'Report update failed.',
        });
        this.setState({
          updating: false,
          updatingRecord: null,
        });
      });
  };

  toggleUpdateModal = (record) => {
    this.setState(state => ({ updating: !state.updating, updatingRecord: record }));
  };

  renderResults = () => {
    const { reports, reportsLoading, session } = this.props;
    // get list of AD groups and determine if user has admin rights
    const adGroups = JSON.parse(session.get('OKTAACLUserGroups') || '[]');
    const ad = session.get('ad');
    const inventoryAdmin = adGroups.includes('EDE_admin_InventoryReports') || !ad;

    const columns = [
      {
        title: 'Date',
        dataIndex: 'effective_date',
        key: 'date',
        render: record => <span>{record}</span>,
      },
      {
        title: 'Status',
        dataIndex: 'audit.status',
        key: 'status',
      },
      {
        title: 'User',
        dataIndex: 'audit.user',
        key: 'user',
      },
      {
        title: 'Location',
        dataIndex: 'location._id',
        key: 'location._id',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Filename',
        key: 'file',
        render: record => (
          <span>{record.report.file.substring(record.report.file.lastIndexOf('/') + 1)}</span>
        ),
      },
      {
        title: '',
        key: 'action',
        render: record => (
          <span>
            <Dropdown
              overlay={
                <Menu onClick={e => this.handleMenuClick(e, record)}>
                  { /* Update report */ }
                  {record.source_system === 'inventory_audit' && !inventoryAdmin ? null : (
                    <Menu.Item key="update">Update</Menu.Item>
                  )}

                  { /* Download report */ }
                  <Menu.Item key="download">Download</Menu.Item>

                  { /* Copy temporary report URL */ }
                  <Menu.Item key="copy">Copy Temporary URL</Menu.Item>

                  { /* Copy permanent report URL */ }
                  <CopyToClipboard
                    text={record.report.file}
                    onCopy={() => message.success('The Permanent URL has been copied to your clipboard.')}
                  >
                    <Menu.Item key="copy">Copy Permanent URL</Menu.Item>
                  </CopyToClipboard>
                </Menu>
              }
            >
              <Button>
                Actions <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        ),
      },
    ];
    if (reports.get('_items') || reportsLoading) {
      return (
        <Card>
          <StyledTable
            columns={columns}
            pagination={{ defaultPageSize: 20 }}
            size="small"
            dataSource={reports.get('_items') ? reports.get('_items').toJS() : null}
            rowKey="_id"
            loading={reportsLoading}
            expandedRowRender={record => (
              <div>
                <h4>Comments:</h4>
                <p>{record.audit.comments ? record.audit.comments : '--'}</p>
                <h4>Logs:</h4>
                <Timeline>
                  {record.audit.log.map(log => (
                    <Timeline.Item key={log.date}>
                      {moment(log.date).format('MMMM Do YYYY, h:mm:ss a')} - {log.status} -{' '}
                      {log.user}
                      {log.comments && ` - ${log.comments}`}
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>
            )}
          />
        </Card>
      );
    }
    return null;
  };

  renderLocationSelect = () => {
    const { form, locations } = this.props;
    const { subsidiaryFilter } = this.state;
    const filteredLocations =
      locations
        .get('_items')
        .filter(location => reportLocations.indexOf(location.get('_id')) >= 0) || fromJS([]);

    const reportingLocationsList = [];

    filteredLocations.toJS().map((location) => {
      const operatingUnits = location.operating_units;
      if (operatingUnits) {
        operatingUnits.map((unit) => {
          if (
            getReportTypes('revenue-integration', unit.id).length ||
            getReportTypes('order-to-cash', unit.id).length ||
            getReportTypes('reporting', unit.id).length ||
            getReportTypes('blackline-journal', unit.id).length ||
            getReportTypes('inventory', unit.id).length
          ) {
            reportingLocationsList.push({
              locId: location._id,
              locName: location.name,
              ouId: unit.id,
              ouName: unit.name,
              subsidiary: location.subsidiary,
            });
          }
          return null;
        });
      }
      return null;
    });

    const reportingLocations = fromJS(reportingLocationsList).sortBy(loc => loc.get('ouId'));

    if (subsidiaryFilter) {
      return form.getFieldDecorator('location', {
        rules: [{ required: true, message: 'Please select your location!' }],
      })(
        <Select
          showSearch
          placeholder="Please select a location"
          key="location-select"
          getPopupContainer={() => document.getElementById('layout-content')}
          onChange={(e) => {
            this.setState({ selectedLocation: e.split('|')[0] });
          }}
        >
          {reportingLocations
            .filter(location => location.get('subsidiary') === subsidiaryFilter)
            .map(loc => (
              <Select.Option
                value={`${loc.get('ouId')}|${loc.get('ouName')}|${loc.get('locId')}|${loc.get(
                  'locName',
                )}`}
                key={loc.get('ouId')}
              >
                {`${loc.get('ouId')} - ${loc.get('ouName')}`}
              </Select.Option>
            ))}
        </Select>,
      );
    }
    return form.getFieldDecorator('location', {
      rules: [{ required: true, message: 'Please select your location!' }],
    })(
      <Select
        showSearch
        placeholder="Please select a location"
        key="location-select"
        getPopupContainer={() => document.getElementById('layout-content')}
        onChange={(e) => {
          this.setState({ selectedLocation: e.split('|')[0] });
        }}
      >
        <Select.Option value="All" key="All">
          All
        </Select.Option>
        {reportingLocations.map(loc => (
          <Select.Option
            value={`${loc.get('ouId')}|${loc.get('ouName')}|${loc.get('locId')}|${loc.get(
              'locName',
            )}`}
            key={loc.get('ouId')}
          >
            {`${loc.get('ouId')} - ${loc.get('ouName')}`}
          </Select.Option>
        ))}
      </Select>,
    );
  };

  render() {
    const { checked, authenticated, session } = this.props;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    const { form, meta } = this.props;
    const spinIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;
    const subsidiaries = meta.get('subsidiaries') ? meta.get('subsidiaries').toJS() : [];
    return (
      <StyledContent>
        <Helmet>
          <title>Reports Audit</title>
          <meta name="description" content="Enterprise Data Explorer Reports" />
        </Helmet>
        <PageHeader title="Reports">
          <h1>Reports Audit</h1>
        </PageHeader>
        <Section>
          <Card>
            <Form onSubmit={this.handleSubmit}>
              <FormItem {...formItemLayout} label="Subsidiary" key="subsidiary">
                {form.getFieldDecorator('subsidiary', {
                  rules: [{ required: false, message: 'Please select a location!' }],
                  initialValue: 'All',
                  onChange: this.onSubChange,
                })(
                  <Select
                    showSearch
                    placeholder="Please select a location"
                    key="location-select"
                    getPopupContainer={() => document.getElementById('layout-content')}
                  >
                    <Select.Option value="All" key="All">
                      All
                    </Select.Option>
                    {subsidiaries &&
                      subsidiaries.map((sub) => {
                        if (sub._id !== 'PMAX' && sub._id !== '-') {
                          return (
                            <Select.Option
                              value={`${sub._id}|${sub.business_unit}|${sub.name}`}
                              key={sub._id}
                            >
                              {sub.business_unit} - {sub.name}
                            </Select.Option>
                          );
                        }
                        return null;
                      })}
                  </Select>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Operating Unit" key="location">
                {this.renderLocationSelect()}
              </FormItem>
              <FormItem {...formItemLayout} label="Date" key="date">
                {form.getFieldDecorator('date', {
                  rules: [{ required: true, message: 'Please select a date range!' }],
                  initialValue: [moment().startOf('month'), moment().endOf('month')],
                })(
                  <DatePicker.RangePicker
                    format="YYYY/MM/DD"
                    getPopupContainer={() => document.getElementById('layout-content')}
                    ranges={{
                      'This Month': [moment().startOf('month'), moment().endOf('month')],
                      'Last Month': [
                        moment()
                          .subtract(1, 'months')
                          .startOf('month'),
                        moment()
                          .subtract(1, 'months')
                          .endOf('month'),
                      ],
                    }}
                  />,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Status" key="status">
                {form.getFieldDecorator('status', {
                  rules: [{ required: false, message: '' }],
                  initialValue: 'All',
                })(
                  <Select
                    showSearch
                    key="status-select"
                    getPopupContainer={() => document.getElementById('layout-content')}
                  >
                    <Select.Option value="All" key="All">All</Select.Option>
                    <Select.Option value="uploaded" key="uploaded">Uploaded</Select.Option>
                    <Select.Option value="void" key="void">Void</Select.Option>
                    <Select.Option value="accepted" key="accepted">Accepted</Select.Option>
                    <Select.Option value="rejected" key="rejected">Rejected</Select.Option>
                    <Select.Option value="under_review" key="under_review">Under Review</Select.Option>
                    <Select.Option value="upload_pending" key="upload_pending">Upload Pending</Select.Option>
                  </Select>,
                )}
              </FormItem>
              <FormItem {...formItemLayout} label="Report Type" key="report">
                {form.getFieldDecorator('report', {
                  rules: [{ required: true, message: 'Please select report type!' }],
                })(
                  <Select
                    showSearch
                    placeholder="Please select a report type"
                    key="report-select"
                    getPopupContainer={() => document.getElementById('layout-content')}
                  >
                    <Select.Option value="All" key="All">
                      All
                    </Select.Option>
                    {[
                      ...getReportTypes('revenue-integration', this.state.selectedLocation),
                      ...getReportTypes('order-to-cash', this.state.selectedLocation),
                      ...getReportTypes('reporting', this.state.selectedLocation),
                      ...getReportTypes('blackline-journal', this.state.selectedLocation),
                      ...getReportTypes('inventory', this.state.selectedLocation),
                    ].map(report => (
                      <Select.Option value={report} key={report}>
                        {report}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
              {this.props.form.getFieldValue('report') === 'Journal Backup' ? (
                <FormItem
                  {...formItemLayout}
                  label="Blackline Journal ID"
                  hasFeedback
                  key="blacklineJournalId"
                >
                  {form.getFieldDecorator('blacklineJournalId', {
                    rules: [{ required: false }],
                  })(<Input placeholder="Blackline Journal Id" />)}
                </FormItem>
              ) : null}
              {this.state.uploading ? (
                <Spin indicator={spinIcon} />
              ) : (
                <FormItem wrapperCol={{ span: 12, offset: 6 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </FormItem>
              )}
            </Form>
          </Card>
        </Section>
        <Section>
          {checked && !authenticated ? (
            <Card>
              <Alert
                message="You must login to access the reports portal."
                description=""
                type="error"
              />
            </Card>
          ) : (
            this.renderResults()
          )}
          <UpdateReportModal
            user={session.get('developer.email')}
            visible={this.state.updating}
            onCancel={this.toggleUpdateModal}
            onCreate={this.updateReport}
          />
        </Section>
      </StyledContent>
    );
  }
}

ReportsPortalAuditPage.propTypes = {
  onLoadReports: PropTypes.func,
  //   locationsLoading: PropTypes.bool,
  reportsLoading: PropTypes.bool,
  locations: ImmutablePropTypes.map, // eslint-disable-line
  reports: ImmutablePropTypes.map, // eslint-disable-line
  session: ImmutablePropTypes.map, // eslint-disable-line
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
  form: PropTypes.object, // eslint-disable-line
  meta: ImmutablePropTypes.map, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.object.isRequired, // eslint-disable-line
};

ReportsPortalAuditPage.defaultProps = {
  onLoadReports: () => console.warn('onLoadReports is not defined'), // eslint-disable-line
  //   locationsLoading: false,
  reportsLoading: false,
  locations: fromJS({}),
  reports: fromJS({}),
  checked: false,
  authenticated: false,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLoadReports: (id, sub, status, startDate, endDate, report, ouid, journalId, token, entitlements) => {
      dispatch(
        loadReports(id, sub, status, startDate, endDate, report, ouid, journalId, token, entitlements),
      );
    },
  };
}

const mapStateToProps = createStructuredSelector({
  locations: makeSelectLocations(),
  locationsLoading: makeSelectLocationsLoading(),
  validation: makeSelectValidation(),
  checked: makeSelectSessionChecked(),
  session: makeSelectSessionUser(),
  authenticated: makeSelectSessionAuthenticated(),
  meta: makeSelectMeta(),
  entitlements: makeSelectSessionUserEntitlements(),
  reports: makeSelectReports(),
  reportsLoading: makeSelectReportsLoading(),
});

const WrappedReportsPortalAuditPage = Form.create()(ReportsPortalAuditPage);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);
const withReducer = injectReducer({ key: 'reportsAudit', reducer });
const withSaga = injectSaga({ key: 'reportsAudit', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(WrappedReportsPortalAuditPage);
