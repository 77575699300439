/**
 * Header
 */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown, Avatar, Menu } from 'antd';
import { withRouter, Link } from 'react-router-dom';
import { stringify } from 'qs';

// STYLES
const Navbar = styled.header`
  position: relative;
  height: 100%;
`;

const Right = styled.div`
  float: right;
  .ant-dropdown-trigger {
    padding: 0 12px;
    display: inline-block;
    transition: background 300ms;
    &:hover {
      background: #e6f7ff;
    }
  }
`;

const Logo = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 130px;
  height: 42px;
  margin: 11px 0 11px 20px;
  background-image: ${props => props.url && `url(${props.url})`};
  background-size: auto 41px;
  transition: width 0s;
`;

const LoginLink = styled.a`
  padding: 0 12px;
  display: inline-block;
`;

const StyledAvatar = styled(Avatar)`
  vertical-align: middle;
  margin-right: 12px;
`;

const StyledTitle = styled.h2`
  vertical-align: top;
  display: inline-block;
  margin-left: 24px;
  color: rgba(0, 0, 0, 0.45);
  @media (max-width: 396px) {
    display: ${props => (props.login ? 'inline-block' : 'none')};
  }
`;

/** Header component */
const Header = ({
  history, location, logoutAction, user, link, heading,
}) => {
  /** Logout action */
  const menuClickAction = ({ key }) => {
    if (key === 'logout') logoutAction(history);
  };

  /** Dropdown menu */
  const menu = (
    <Menu onClick={menuClickAction}>
      <Menu.Item key="logout">Sign Out</Menu.Item>
    </Menu>
  );
  const showLogin =
    location.pathname.indexOf('login') < 0 && location.pathname.indexOf('legacy') < 0;
  return (
    <Navbar>
      <Link to={link}>
        {heading ? (
          <StyledTitle
            login={
              location.pathname.indexOf('login') > -1 || location.pathname.indexOf('legacy') > -1
            }
          >
            {heading}
          </StyledTitle>
        ) : (
          <Logo
            url={
              process.env.REACT_APP_BASENAME
                ? `${process.env.REACT_APP_BASENAME}/static/img/logo.png`
                : '/static/img/logo.png'
            }
          />
        )}
      </Link>
      <Right>
        {user ? (
          <Dropdown overlay={menu} placement="bottomLeft">
            <span>
              <StyledAvatar style={{ color: '#004B78', backgroundColor: '#00A0D2' }}>
                {user[0].toUpperCase()}
              </StyledAvatar>
              {user}
            </span>
          </Dropdown>
        ) : (
          showLogin && (
            <LoginLink
              onClick={() => {
                window.location = `/login?${stringify({ redirect: (location.pathname + location.search).substr(1) })}`;
                return null;
              }}
            >
              Login
            </LoginLink>
          )
        )}
      </Right>
    </Navbar>
  );
};

Header.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line
  logoutAction: PropTypes.func.isRequired,
  user: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  link: PropTypes.string,
  heading: PropTypes.string,
};

Header.defaultProps = {
  user: null,
  location: {
    pathname: '',
  },
  link: '/',
  heading: null,
};

export default withRouter(Header); // passing router to provide context to links - error without
