/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Layout } from 'antd';
import { createStructuredSelector } from 'reselect';
import ImmutablePropTypes from 'react-immutable-proptypes';

// INTERNALS
import Header from '../shared/components/Header/index';
import ReportsSidebar from '../shared/components/ReportsSidebar/index';
import ReportsRouter from './reportsRouter';

// ACTIONS
import { authLogout } from './actions';

// SELECTORS
import {
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectLocation,
  makeSelectSessionAuthenticated,
  makeSelectSessionChecked,
  makeSelectSessionUserEmail,
  makeSelectSessionUser,
  makeSelectSessionUserEntitlements,
  makeSelectSessionTimeout,
} from './selectors';

// STYLES
import { StyledSider, StyledLayout, StyledLayoutHeader, StyledLayoutContent } from './styles';

/**
 * App container componentent
 */
export class EDEApp extends React.Component {
  constructor(props) {
    super(props);
    this.scrollContainer = null;
    this.state = {
      collapsed: false,
    };
  }

  /** menu collapse action */
  onCollapse = () => this.setState({ collapsed: !this.state.collapsed });

  /** Render App */
  render() {
    const {
      location, onLogout, user, entitlements,
    } = this.props;

    const minLayout =
      location.pathname.indexOf('login') > -1 || location.pathname.indexOf('legacy') > -1; // flag to hide navigation ui on pags

    return (
      <StyledLayout>
        <Helmet titleTemplate="%s - Delaware North Data Explorer" defaultTitle="Reports Portal">
          <meta name="description" content="Delaware North Reports Portal" />
        </Helmet>

        {!minLayout && (
          <StyledSider collapsible breakpoint="md" width={256} onCollapse={this.onCollapse}>
            <ReportsSidebar
              location={location}
              collapsed={this.state.collapsed}
              entitlements={entitlements}
            />
          </StyledSider>
        )}

        <Layout>
          <StyledLayoutHeader>
            <Header
              logoutAction={onLogout}
              user={user}
              heading="Delaware North Reports Portal"
              link="/reports-portal"
            />
          </StyledLayoutHeader>
          <StyledLayoutContent
            ref={(node) => {
              this.scrollContainer = node;
            }}
            id="layout-content"
          >
            <ReportsRouter
              checked={this.props.checked}
              authenticated={this.props.authenticated}
              accessToken={this.props.accessToken}
              location={this.props.location}
            />
          </StyledLayoutContent>
        </Layout>
      </StyledLayout>
    );
  }
}

EDEApp.propTypes = {
  onLogout: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  user: PropTypes.string,
  session: ImmutablePropTypes.map, // eslint-disable-line
  locationsError: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]), // eslint-disable-line
  locations: ImmutablePropTypes.map, // eslint-disable-line
  entitlements: PropTypes.object, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  accessToken: PropTypes.string,
};

EDEApp.defaultProps = {
  onLogout: () => {
    console.warn('onLogout not defined in reportsApp Component'); // eslint-disable-line
  },
  location: {
    pathname: '',
  },
  checked: false,
  authenticated: false,
  user: null,
  session: null,
  accessToken: null,
};

export function mapDispatchToProps(dispatch) {
  return {
    onLogout: (history) => {
      dispatch(authLogout(history));
    },
  };
}

const mapStateToProps = createStructuredSelector({
  location: makeSelectLocation(),
  locations: makeSelectLocations(),
  locationsError: makeSelectLocationsError(),
  authenticated: makeSelectSessionAuthenticated(),
  checked: makeSelectSessionChecked(),
  user: makeSelectSessionUserEmail(),
  session: makeSelectSessionUser(),
  entitlements: makeSelectSessionUserEntitlements(),
  sessionTimeout: makeSelectSessionTimeout(),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(EDEApp);
