/*
 * AppConstants
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'ede/App/YOUR_ACTION_CONSTANT';
 */

// Auth Constants
export const LOAD_AUTH = 'ede/App/LOAD_AUTH';
export const LOAD_AUTH_SUCCESS = 'ede/App/LOAD_AUTH_SUCCESS';
export const LOAD_AUTH_ERROR = 'ede/App/LOAD_AUTH_ERROR';
export const CLEAR_AUTH_ERROR = 'ede/App/CLEAR_AUTH_ERROR';

// Auth AD Constants
export const LOAD_AUTH_AD = 'ede/App/LOAD_AUTH_AD';

// Location Constants
export const LOAD_LOCATIONS = 'ede/App/LOAD_LOCATIONS';
export const LOAD_LOCATIONS_SUCCESS = 'ede/App/LOAD_LOCATIONS_SUCCESS';
export const LOAD_LOCATIONS_ERROR = 'ede/App/LOAD_LOCATIONS_ERROR';

// Validation Constants
export const LOAD_VALIDATION = 'ede/App/LOAD_VALIDATION';
export const LOAD_VALIDATION_SUCCESS = 'ede/App/LOAD_VALIDATION_SUCCESS';
export const LOAD_VALIDATION_ERROR = 'ede/App/LOAD_VALIDATION_ERROR';

// Meta Constants
export const LOAD_META = 'ede/App/LOAD_META';
export const LOAD_META_SUCCESS = 'ede/App/LOAD_META_SUCCESS';
export const LOAD_META_ERROR = 'ede/App/LOAD_META_ERROR';

export const SESSION_TIME_OUT = 'ede/App/SESSION_TIME_OUT';
export const RESET_SESSION_TIME_OUT = 'ede/App/RESET_SESSION_TIME_OUT';

export const LOG_OUT = 'ede/App/LOG_OUT';
