/*
 * Activity Constants
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'ede/ActivityPage/YOUR_ACTION_CONSTANT';
 */

export const LOAD_LOCATIONS_FILTER = 'ede/ActivityPage/LOAD_LOCATIONS_FILTER';
export const LOAD_LOCATIONS_FILTER_SUCCESS = 'ede/ActivityPage/LOAD_LOCATIONS_FILTER_SUCCESS';
export const LOAD_LOCATIONS_FILTER_ERROR = 'ede/ActivityPage/LOAD_LOCATIONS_FILTER_ERROR';

export const LOAD_SUBSIDIARY_FILTER = 'ede/ActivityPage/LOAD_SUBSIDIARY_FILTER';
export const LOAD_SUBSIDIARY_FILTER_SUCCESS = 'ede/ActivityPage/LOAD_SUBSIDIARY_FILTER_SUCCESS';
export const LOAD_SUBSIDIARY_FILTER_ERROR = 'ede/ActivityPage/LOAD_SUBSIDIARY_FILTER_ERROR';

export const LOAD_RESOURCE_FILTER = 'ede/ActivityPage/LOAD_RESOURCE_FILTER';
export const LOAD_RESOURCE_FILTER_SUCCESS = 'ede/ActivityPage/LOAD_RESOURCE_FILTER_SUCCESS';
export const LOAD_RESOURCE_FILTER_ERROR = 'ede/ActivityPage/LOAD_RESOURCE_FILTER_ERROR';

export const LOAD_SOURCE_FILTER = 'ede/ActivityPage/LOAD_SOURCE_FILTER';
export const LOAD_SOURCE_FILTER_SUCCESS = 'ede/ActivityPage/LOAD_SOURCE_FILTER_SUCCESS';
export const LOAD_SOURCE_FILTER_ERROR = 'ede/ActivityPage/LOAD_SOURCE_FILTER_ERROR';
