import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Select } from 'antd';

const FormItem = Form.Item;

const UpdateForm = ({
  user, visible, onCancel, onCreate, form,
}) => (
  <Modal
    visible={visible}
    title="Update Report"
    okText="Update"
    onCancel={() => {
      form.resetFields();
      onCancel();
    }}
    onOk={() => {
      form.validateFields((err, values) => {
        if (!err) {
          onCreate(err, values);
          form.resetFields();
        }
      });
    }}
  >
    <Form layout="vertical">
      <FormItem label="User">
        {form.getFieldDecorator('user', {
          rules: [{ required: true, message: 'Please input the user' }],
          initialValue: user,
        })(<Input disabled />)}
      </FormItem>
      <FormItem label="Comments">
        {form.getFieldDecorator('comments', { rules: [{ required: true }] })(
          <Input type="textarea" />,
        )}
      </FormItem>
      <FormItem label="Report Status" className="collection-create-form_last-form-item">
        {form.getFieldDecorator('status', {
          rules: [{ required: true, message: 'Please select the status' }],
          initialValue: 'uploaded',
        })(
          <Select>
            <Select.Option value="uploaded">Uploaded</Select.Option>
            <Select.Option value="under_review">Under Review</Select.Option>
            <Select.Option value="accepted">Accepted</Select.Option>
            <Select.Option value="rejected">Rejected</Select.Option>
            <Select.Option value="void">Void</Select.Option>
          </Select>,
        )}
      </FormItem>
    </Form>
  </Modal>
);

UpdateForm.propTypes = {
  user: PropTypes.string,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreate: PropTypes.func,
  form: PropTypes.object, // eslint-disable-line
};

UpdateForm.defaultProps = {
  user: '',
  visible: false,
  onCancel: () => console.warn('onCancel not defined'),
  onCreate: () => console.warn('onCreate not defined'),
};

const WrappedUpdateForm = Form.create()(UpdateForm);
export default WrappedUpdateForm;
