/**
 *
 * Sidebar
 *
 * This component is the the global side nav. Appears on every page of the site.
 *
 * TODO:
 * - finalize the links
 * - implement the collapse
 */

import React from 'react';
import styled from 'styled-components';
import { NavLink, Link } from 'react-router-dom';
import { Menu, Icon } from 'antd';
import PropTypes from 'prop-types';

// STYLES
const SidebarStyled = styled.div`
  ${'' /* width: 256px;
  background-color: rgb(0, 21, 41);
  background-color: white; */};
`;

const StyledMenu = styled(Menu)`
  border-right: 0px;
`;

const Logo = styled.div`
  width: ${props => (props.collapsed ? '40px' : '100%')};
  vertical-align: top;
  height: 42px;
  margin: 11px 0 11px 20px;
  background-image: ${props => props.url && `url(${props.url})`};
  background-size: auto 41px;
  transition: width 0s;
  transition-delay: ${props => (props.collapsed ? '0s' : '300ms')};
`;

/**
 * Get the current active url link from the location prop
 *
 * @param {*} props component props
 */
const getCurrentMenuSelectedKeys = (location) => {
  if (location) {
    const { pathname } = location;
    const keys = pathname.split('/').slice(1);
    return pathname === '/explorer' ? ['home'] : keys;
  }
  return null;
};
const Sidebar = ({ location, collapsed, entitlements }) => (
  <SidebarStyled>
    <Link to="/">
      <Logo
        collapsed={collapsed}
        url={
          process.env.REACT_APP_BASENAME
            ? `${process.env.REACT_APP_BASENAME}/static/img/logo.png`
            : '/static/img/logo.png'
        }
      />
    </Link>
    <StyledMenu
      selectedKeys={getCurrentMenuSelectedKeys(location)}
      mode="inline"
      theme="light"
      defaultOpenKeys={['reports-portal']}
    >
      <Menu.Item key="home">
        <NavLink to="/explorer">
          <Icon type="dashboard" />
          <span>Dashboard</span>
        </NavLink>
      </Menu.Item>
      <Menu.Item key="locations">
        <NavLink to="/explorer/locations">
          <Icon type="environment-o" />
          <span>Locations</span>
        </NavLink>
      </Menu.Item>
      {entitlements.Resources &&
      (entitlements.Resources.includes('catalog') || entitlements.Resources.includes('*')) ? (
        <Menu.Item key="catalog">
          <NavLink to="/explorer/catalog">
            <Icon type="folder" />
            <span>Catalog</span>
          </NavLink>
        </Menu.Item>
      ) : null}
      {entitlements.Resources &&
      (entitlements.Resources.includes('validation') || entitlements.Resources.includes('*')) ? (
        <Menu.SubMenu
          key="validation-sub"
          title={
            <span>
              <Icon type="check-circle-o" />
              <span>Validation</span>
            </span>
          }
        >
          <Menu.Item key="validation">
            <NavLink to="/explorer/validation">
              <Icon type="check-circle-o" />
              <span>All Records</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="location-validation">
            <NavLink to="/explorer/location-validation">
              <Icon type="check-circle-o" />
              <span>Validation by Location</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="validation-failures">
            <NavLink to="/explorer/validation-failures">
              <Icon type="close-circle-o" />
              <span>Failures</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      ) : null}
      {entitlements.Resources &&
      (entitlements.Resources.includes('activity') || entitlements.Resources.includes('*')) ? (
        <Menu.Item key="activity">
          <NavLink to="/explorer/activity">
            <Icon type="share-alt" />
            <span>Activity</span>
          </NavLink>
        </Menu.Item>
      ) : null}
      {entitlements.Resources &&
      (entitlements.Resources.includes('transactions') || entitlements.Resources.includes('*')) ? (
        <Menu.SubMenu
          key="transactions-report-sub"
          title={
            <span>
              <Icon type="audit-o" />
              <span>POS Transaction Reports</span>
            </span>
          }
        >
          <Menu.Item key="transactions-report-daily-summary">
            <NavLink to="/explorer/transactions-report/daily-summary">
              <Icon type="audit-o" />
              <span>Daily Summary</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="transactions-report-daily-location-summary">
            <NavLink to="/explorer/transactions-report/daily-location-summary">
              <Icon type="audit-o" />
              <span>Daily Location Summary</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="transactions-report-daily-location-tenders">
            <NavLink to="/explorer/transactions-report/daily-location-tenders">
              <Icon type="dollar-o" />
              <span>Daily Location Tenders</span>
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      ) : null}
    </StyledMenu>
  </SidebarStyled>
);

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  collapsed: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
};

Sidebar.defaultProps = {
  location: {
    pathname: '',
  },
  collapsed: false,
  entitlements: {},
};

export default Sidebar;
