/**
 * App Sagas
 *
 * Gets the app data
 */
import formurlencoded from 'form-urlencoded';
import { sessionService } from 'redux-react-session';
import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';

// INTERNALS
import request from './../utils/request';
import { createInFilterString } from './../utils/query';

// CONSTANTS
import {
  LOAD_AUTH,
  LOAD_AUTH_SUCCESS,
  LOAD_LOCATIONS,
  LOAD_AUTH_AD,
  LOAD_VALIDATION,
  LOAD_META,
} from './constants';

// ACTIONS
import {
  locationsLoaded,
  locationsLoadingError,
  authLoaded,
  authLoadingError,
  validationLoaded,
  validationLoadingError,
  metaLoadingError,
  metaLoaded,
} from './actions';

/**
 * location data request/response handler
 */
export function* getLocationData({ token, entitlements }) {
  let filters;
  if (entitlements && !entitlements.Locations.includes('*')) {
    const where = { _id: createInFilterString(entitlements.Locations) };
    filters = where;
  }
  let requestURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/locations?max_results=1000&${
    filters ? `where=${JSON.stringify(filters)}` : ''
  }&sort=name`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    requestURL = `${requestURL}&apikey=${process.env.REACT_APP_API_KEY}`;
  }
  try {
    const data = yield call(request, requestURL, options);
    yield put(locationsLoaded(data));
  } catch (err) {
    yield put(locationsLoadingError(err));
  }
}

/**
 * app data request/response handler
 */
export function* getAuthData({ client, history, redirect }) {
  const requestURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/oauth/client_credential/accesstoken?grant_type=client_credentials`;

  // encode client data
  const credentials = formurlencoded({
    client_id: client.id,
    client_secret: client.secret,
  });

  // fetch request options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-Control': 'no-cache',
    },
    body: credentials,
  };
  try {
    const data = yield call(request, requestURL, options);
    yield put(authLoaded(data, history, redirect));
  } catch (err) {
    yield put(authLoadingError(err));
  }
}

/**
 * app auth AD request/response handler
 */
export function* getAuthADData({ client, history, redirect }) {
  // const requestURL = 'https://dnc-test.apigee.net/v1/externalAuth/oktaidm/token?grant_type=password';

  const requestURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/v1/externalAuth/oktaidm/token?grant_type=password`;

  // encode client data
  const credentials = formurlencoded({
    username: client.id,
    password: client.secret,
  });

  // fetch request options
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Cache-Control': 'no-cache',
      Authorization: window.btoa(
        `${process.env.REACT_APP_REPORTS_API_KEY}:${process.env.REACT_APP_REPORTS_API_SECRET}`,
      ),
      AppKey: process.env.REACT_APP_REPORTS_API_KEY,
    },
    body: credentials,
  };
  try {
    const data = yield call(request, requestURL, options);
    yield put(authLoaded(data, history, redirect));
  } catch (err) {
    yield put(authLoadingError(err));
  }
}

/**
 * set the session data
 */
export function* setSessionData({ token, data, redirect }) {
  try {
    let user = {};
    if (data.OktaUserProfile) {
      const oktaProfile = JSON.parse(data.OktaUserProfile);
      user = data;
      user.ad = true;
      user.email = oktaProfile.login;
      user.entitlements =
        data.entitlements ||
        '{"Allow": [{"Methods": ["GET"], "Locations": "*", "Resources": "*", "SourceSystems": "*"}, {"Resources": ["reports"], "SourceSystems": "*", "Locations": "*", "Methods": ["GET", "POST", "PATCH", "DELETE"]}]}';
    } else {
      user = data;
      user.ad = false;
      user.email = data['developer.email'];
      user.entitlements = data.entitlements;
    }
    yield sessionService.saveSession(token);
    yield sessionService.saveUser(user);
    if (redirect) {
      window.location = redirect;
    } else {
      window.location = '/';
    }
  } catch (err) {
    yield put(authLoadingError(err));
  }
}

/**
 * validation data request/response handler
 */
export function* getValidationData({ token, entitlements }) {
  let filters;
  let locations;
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!filters) filters = {};
    filters['location._id'] = createInFilterString(entitlements.Locations);
    locations = { _id: createInFilterString(entitlements.Locations) };
  }
  if (entitlements && !entitlements.Resources.includes('*')) {
    if (!filters) filters = {};
    filters.resource = createInFilterString(entitlements.Resources);
  }
  if (entitlements && !entitlements.SourceSystems.includes('*')) {
    if (!filters) filters = {};
    filters.source_system = createInFilterString(entitlements.SourceSystems);
  }
  const date = moment()
    .subtract(1, 'day')
    .format('YYYY-MM-DD');
  let where = {
    date: { $gte: date, $lte: date },
  };
  if (filters) {
    where = {
      ...where,
      ...filters,
    };
  }
  let validationURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/validation?max_results=1000&projection={"issues":0}&where=${JSON.stringify(
    where,
  )}`;
  let locURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/locations?max_results=1000&projection={"_id":1,"name":1}&sort=name
    ${locations ? `&where=${JSON.stringify(locations)}` : ''}`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    validationURL = `${validationURL}&apikey=${process.env.REACT_APP_API_KEY}`;
    locURL = `${locURL}&apikey=${process.env.REACT_APP_API_KEY}`;
  }
  try {
    const valData = yield call(request, validationURL, options);
    const locData = yield call(request, locURL, options);
    yield put(validationLoaded(valData, locData));
  } catch (err) {
    yield put(validationLoadingError(err));
  }
}

/**
 * meta data request/response handler
 */
export function* getMetaData({ token, entitlements }) {
  let locations;
  let resources;
  let sourceSystems;
  if (entitlements && !entitlements.Locations.includes('*')) {
    if (!locations) locations = {};
    locations._id = createInFilterString(entitlements.Locations);
  }
  if (entitlements && !entitlements.Resources.includes('*')) {
    if (!resources) resources = {};
    resources.resource = createInFilterString(entitlements.Resources);
  }
  if (entitlements && !entitlements.SourceSystems.includes('*')) {
    if (!sourceSystems) sourceSystems = {};
    sourceSystems.source_system = createInFilterString(entitlements.SourceSystems);
  }

  let subsidiariesURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/subsidiaries?max_results=1000&sort=business_unit`;
  let sourceSystemsURL = `${process.env.REACT_APP_EDC_HOST ||
    ''}/dnc/edc/v1/ALL/meta/source_systems${
    sourceSystems ? `?where=${JSON.stringify(sourceSystems)}` : ''
  }`;
  let resourcesURL = `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/ALL/meta/resources${
    resources ? `?where=${JSON.stringify(resources)}` : ''
  }`;
  const options = {
    method: 'GET',
  };
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`, // eslint-disable-line
    };
  } else {
    subsidiariesURL = `${subsidiariesURL}&apikey=${process.env.REACT_APP_API_KEY}`;
    sourceSystemsURL = `${sourceSystemsURL}${sourceSystems ? '&' : '?'}apikey=${
      process.env.REACT_APP_API_KEY
    }`;
    resourcesURL = `${resourcesURL}${resources ? '&' : '?'}apikey=${process.env.REACT_APP_API_KEY}`;
  }
  try {
    const subData = yield call(request, subsidiariesURL, options);
    const srcData = yield call(request, sourceSystemsURL, options);
    const resData = yield call(request, resourcesURL, options);
    yield put(metaLoaded(subData._items ? subData._items : subData, srcData, resData));
  } catch (err) {
    yield put(metaLoadingError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* appData() {
  yield takeLatest(LOAD_AUTH, getAuthData);
  yield takeLatest(LOAD_AUTH_AD, getAuthADData);
  yield takeLatest(LOAD_LOCATIONS, getLocationData);
  yield takeLatest(LOAD_AUTH_SUCCESS, setSessionData);
  yield takeLatest(LOAD_VALIDATION, getValidationData);
  yield takeLatest(LOAD_META, getMetaData);
}
