/**
 *
 * App Selectors
 *
 * The global state selectors
 */

import { createSelector } from 'reselect';

/** global state */
const selectGlobal = state => state.get('global');

/** route state */
const selectRoute = state => state.get('router');

/** session state */
const selectSession = state => state.get('session');

/** browser state */
const selectBrowser = state => state.get('browser');

const makeSelectBrowser = () =>
  createSelector(
    selectBrowser,
    browserState => browserState,
  );

/** session state */
const makeSelectSession = () =>
  createSelector(
    selectSession,
    sessionState => sessionState,
  );

/** session checked */
const makeSelectSessionChecked = () =>
  createSelector(
    selectSession,
    sessionState => sessionState.get('checked'),
  );

/** session authenticated */
const makeSelectSessionAuthenticated = () =>
  createSelector(
    selectSession,
    sessionState => sessionState.get('authenticated'),
  );

/** session user */
const makeSelectSessionUser = () =>
  createSelector(
    selectSession,
    sessionState => sessionState.get('user'),
  );

/** session user email */
const makeSelectSessionUserEmail = () =>
  createSelector(
    selectSession,
    sessionState => sessionState.get('user').get('email'),
  );

/** session user entitlements */
const makeSelectSessionUserEntitlements = () =>
  createSelector(
    selectSession,
    (sessionState) => {
      if (
        sessionState.get('user') &&
        sessionState.get('user').get('entitlements') &&
        JSON.parse(sessionState.get('user').get('entitlements')).Allow
      ) {
        const allow = JSON.parse(sessionState.get('user').get('entitlements')).Allow;
        const entitlements = {
          Methods: [],
          Locations: [],
          Resources: [],
          SourceSystems: [],
        };
        allow.map((rule) => {
          entitlements.Methods = [...rule.Methods, ...entitlements.Methods];
          entitlements.Locations = [...rule.Locations, ...entitlements.Locations];
          entitlements.Resources = [...rule.Resources, ...entitlements.Resources];
          entitlements.SourceSystems = [...rule.SourceSystems, ...entitlements.SourceSystems];
          return null;
        });
        return entitlements;
      }
      return {};
    },
  );

/** app loading */
// const makeSelectLoading = () =>
//   createSelector(selectGlobal, globalState => globalState.get('loading'));

/** app error */
// const makeSelectError = () => createSelector(selectGlobal,
// globalState => globalState.get('error'));

/** route location */
const makeSelectLocation = () =>
  createSelector(
    selectRoute,
    routeState => routeState.get('location').toJS(),
  );

/** app data */
// const makeSelectAppData = () =>
//   createSelector(selectGlobal, globalState => globalState.get('appData'));

/** locations loading */
const makeSelectLocationsLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('locationsLoading'),
  );

/** locations loading */
const makeSelectLocationsError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('locationsError'),
  );

/** locations */
const makeSelectLocations = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('locationsData'),
  );

/** auth loading */
const makeSelectAuthLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('authLoading'),
  );

const makeSelectAuthError = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('authError'),
  );

const makeSelectValidationLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('validationLoading'),
  );

const makeSelectValidation = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('validationData'),
  );

const makeSelectMetaLoading = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('metaLoading'),
  );

const makeSelectMeta = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('metaData'),
  );

const makeSelectSessionTimeout = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.get('sessionTimeout'),
  );

export {
  selectGlobal,
  // makeSelectLoading,
  // makeSelectError,
  makeSelectLocation,
  // makeSelectAppData,
  makeSelectLocationsLoading,
  makeSelectLocations,
  makeSelectLocationsError,
  makeSelectSession,
  makeSelectAuthLoading,
  makeSelectSessionUser,
  makeSelectSessionChecked,
  makeSelectSessionAuthenticated,
  makeSelectSessionUserEmail,
  makeSelectSessionUserEntitlements,
  makeSelectValidationLoading,
  makeSelectValidation,
  makeSelectMetaLoading,
  makeSelectMeta,
  makeSelectBrowser,
  makeSelectAuthError,
  makeSelectSessionTimeout,
};
