/**
 * ActivityPage Selectors
 *
 * Selects activity data from the redux store
 */

import { createSelector } from 'reselect';
import { initialState } from './reducer';

/** Select activity root */
const selectFilters = state => state.get('filters', initialState);

/** select activity data */
const makeSelectLocations = () =>
  createSelector(
    selectFilters,
    filtersState => filtersState.get('locationsData'),
  );

/** select activity data */
const makeSelectSubsidiary = () =>
  createSelector(
    selectFilters,
    filtersState => filtersState.get('subsidiaryData'),
  );

/** select activity data */
const makeSelectResource = () =>
  createSelector(
    selectFilters,
    filtersState => filtersState.get('resourceData'),
  );

/** select activity data */
const makeSelectSource = () =>
  createSelector(
    selectFilters,
    filtersState => filtersState.get('sourceData'),
  );

export {
  selectFilters,
  makeSelectLocations,
  makeSelectSubsidiary,
  makeSelectResource,
  makeSelectSource,
};
