/**
 * Create the store with dynamic reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';
import { fromJS } from 'immutable';
import { routerMiddleware } from 'connected-react-router/immutable';
import moment from 'moment';
import { sessionService } from 'redux-react-session';
import createSagaMiddleware from 'redux-saga';
import { responsiveStoreEnhancer } from 'redux-responsive';
import createReducer from './reducers';

import requestMiddleware from './utils/requestMiddleware';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) {
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const middlewares = [requestMiddleware, sagaMiddleware, routerMiddleware(history)];

  const enhancers = [responsiveStoreEnhancer, applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle, indent */
  const composeEnhancers =
    process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const store = createStore(createReducer(), fromJS(initialState), composeEnhancers(...enhancers));

  // sessions
  sessionService
    .initSessionService(store)
    .then(() => {
      const session = store.getState().get('session');
      const user = session && session.get('user');
      const expirationDate = moment(parseInt(user.get('issued_at') || '0', 10))
        .add(59, 'm')
        .toDate();
      if (session.get('authenticated') && expirationDate < new Date().valueOf()) {
        sessionService.deleteSession();
        sessionService.deleteUser();
        console.info('Redux React Session was expired and removed from your storage');
      }
      return console.info(
        'Redux React Session is ready and a session was refreshed from your storage',
      ); // eslint-disable-line
    })
    .catch(
      () => console.info('Redux React Session is ready and there is no session in your storage'), // eslint-disable-line
    );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
