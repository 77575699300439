import React from 'react';
import { Helmet } from 'react-helmet';
import { Alert, Row, Col, Card, Icon, Popover } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';

// STYLES
import { StyledContent } from './../../global-styles';

// Selectors

// SELECTORS
import {
  makeSelectSessionUserEntitlements,
  makeSelectSessionUserEmail,
} from './../../App/selectors';

const { Meta } = Card;

const StyledHeader = styled.h1`
  color: #434e59;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 48px;
  padding-top: 15%;
`;

const DisabledCard = styled.div`
  opacity: 0.5;
`;

const cardWidth = 220;

const LandingPage = ({ entitlements, user }) => (
  <StyledContent>
    <Helmet>
      <title>Delaware North Data Solutions</title>
      <meta name="description" content="Delaware North Data Solutions" />
    </Helmet>
    <Row>
      <Alert
        style={{ width: '75%', maxWidth: 750, margin: '0 auto' }}
        message="Important Updates"
        description="Updates have been applied to the strucure of the Data Solutions Platform. Please note URL changes and update your bookmarks and documentation accordingly."
        type="info"
        closable
      />
    </Row>
    <Row style={{ textAlign: 'center' }}>
      <StyledHeader>Delaware North Data Solutions</StyledHeader>
    </Row>
    <Row gutter={24} style={{ maxWidth: '1000px', margin: '0 auto' }} justify="center">
      <Col lg={6} md={24} style={{ textAlign: 'center' }}>
        <Link to="/explorer">
          <Card
            hoverable
            style={{ width: cardWidth, margin: '0 auto 24px' }}
            cover={
              <Icon
                type="compass"
                theme="twoTone"
                style={{ fontSize: '48px', paddingTop: '24px' }}
              />
            }
          >
            <Meta title="Enterprise Data Explorer" description="Explore EDC and EDA Data" />
          </Card>
        </Link>
      </Col>
      <Col lg={6} md={24} style={{ textAlign: 'center' }}>
        {entitlements.Resources &&
        (entitlements.Resources.includes('reports') || entitlements.Resources.includes('*')) ? (
          <Link to="/reports-portal">
            <Card
              hoverable
              style={{ width: cardWidth, margin: '0 auto 24px' }}
              cover={
                <Icon
                  type="folder-open"
                  theme="twoTone"
                  style={{ fontSize: '48px', paddingTop: '24px' }}
                />
              }
            >
              <Meta title="Reports Portal" description="View, Upload, and Update Reports" />
            </Card>
          </Link>
        ) : (
          <Popover content={user ? 'You do not have access.' : 'Log in to access'}>
            <DisabledCard>
              <Card
                style={{ width: cardWidth, margin: '0 auto 24px' }}
                cover={
                  <Icon
                    type="folder-open"
                    theme="twoTone"
                    style={{ fontSize: '48px', paddingTop: '24px' }}
                  />
                }
              >
                <Meta title="Reports Portal" description="View, Upload, and Update Reports" />
              </Card>
            </DisabledCard>
          </Popover>
        )}
      </Col>
      <Col lg={6} md={24} style={{ textAlign: 'center' }}>
        <a
          href="https://confluence.delawarenorth.com/display/EDC/API+Documentation+for+End+Users"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Card
            hoverable
            style={{ width: cardWidth, margin: '0 auto 24px' }}
            cover={
              <Icon
                type="file-text"
                theme="twoTone"
                style={{ fontSize: '48px', paddingTop: '24px' }}
              />
            }
          >
            <Meta title="Documentation" description="Data Solutions Platform Documentation" />
          </Card>
        </a>
      </Col>
      <Col lg={6} md={24} style={{ textAlign: 'center' }}>
        <Popover content="Coming soon">
          <DisabledCard>
            <Card
              style={{ width: cardWidth, margin: '0 auto 24px' }}
              cover={
                <Icon
                  type="exclamation-circle"
                  theme="twoTone"
                  style={{ fontSize: '48px', paddingTop: '24px' }}
                />
              }
            >
              <Meta title="System Status" description="Data Solutions Platform Status" />
            </Card>
          </DisabledCard>
        </Popover>
      </Col>
    </Row>
  </StyledContent>
);

LandingPage.propTypes = {
  user: PropTypes.string,
  entitlements: PropTypes.object, // eslint-disable-line
};

LandingPage.defaultProps = {
  user: null,
};

const mapStateToProps = createStructuredSelector({
  entitlements: makeSelectSessionUserEntitlements(),
  user: makeSelectSessionUserEmail(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(LandingPage);
