/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Alert, Card, Form, Switch, Select } from 'antd';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// INTERNAL
import PageHeader from './../../../shared/components/PageHeader/index';

// SELECTORS
import {
  makeSelectValidation,
  makeSelectLocations,
  makeSelectLocationsLoading,
  makeSelectSessionChecked,
  makeSelectSessionAuthenticated,
  makeSelectSessionUserEntitlements,
  makeSelectSessionUser,
  makeSelectMeta,
} from './../../../App/selectors';

// STYLES
import { Section, StyledContent, StyledTable } from './../../../global-styles';

const FormItem = Form.Item;

class ReportsPortalUploadPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ouConfigQuery: null,
      reportingOus: null,
    };

    this.onChangeHandler = this.onChangeHandler.bind(this);
  }

  componentDidMount() {
    this.setReportingOus();
  }

  componentDidUpdate(prevProps, prevState) {
    // Verify a state change took place because setReportingOus will also retrieve
    // the inventory depot configuration upon every prop change.
    if (prevState.reportingOus !== this.state.reportingOus) {
      this.setReportingOus();
    }
  }

  onChangeHandler = record => (enabled) => {
    const { reportingOus } = this.state;
    let newReportingOus = [...reportingOus];
    const index = newReportingOus.findIndex(item => record.key === item.get('key'));
    newReportingOus = reportingOus.setIn([index, 'enabled'], enabled);
    this.setState({ reportingOus: fromJS(newReportingOus) });

    return enabled ? this.activateLocation(record) : this.deactivateLocation(record);
  };

  getInventoryDepotConfig = () => {
    const { session } = this.props;
    return fetch(`${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/ALL/inventorydepot/config?max_results=1000`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${session.get('access_token')}`,
        'Cache-Control': 'no-cache',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Report error getting data');
      })
      .then(response => fromJS(response._items));
  };

  setReportingOus = () => {
    const { locations } = this.props;

    // Retrieve the current inventory depot config since it provides the 'enabled' status of each
    // operating unit.
    this.getInventoryDepotConfig().then((inventoryDepotConfig) => {
      const locationStatus = {};
      inventoryDepotConfig.forEach((loc) => {
        locationStatus[loc.get('_id')] = loc.get('enabled');
      });
      if (!this.state.reportingOus) {
        const reportingOuList = [];
        locations.get('_items').map((location) => {
          const operatingUnits = location.get('operating_units');
          if (operatingUnits) {
            operatingUnits.map((unit) => {
              reportingOuList.push({
                key: `${unit.get('id')}+${location.get('_id')}`,
                id: unit.get('id'),
                name: unit.get('name'),
                loc: location.get('_id'),
                enabled: locationStatus[`${location.get('_id')}+${unit.get('id')}`] === true,
              });
              return null;
            });
          }
          return null;
        });
        this.setState({ reportingOus: fromJS(reportingOuList).sortBy(ou => ou.get('id')) });
      }
    });
  };

  activateLocation = (record) => {
    const { session } = this.props;
    const configId = `${record.loc}+${record.id}`;
    const reqData = {
      _id: configId,
      enabled: true,
      location: { _id: record.loc, operating_unit: { _id: record.id } },
    };
    return fetch(
      `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/inventorydepot/config/${configId}`,
      {
        method: 'PUT',
        body: JSON.stringify(reqData),
        headers: {
          Authorization: `Bearer ${session.get('access_token')}`,
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .catch(error => console.error('Error: ', error));
  };

  deactivateLocation = (record) => {
    const { session } = this.props;
    const configId = `${record.loc}+${record.id}`;
    const reqData = {
      _id: configId,
      enabled: false,
      location: { _id: record.loc, operating_unit: { _id: record.id } },
    };
    return fetch(
      `${process.env.REACT_APP_EDC_HOST || ''}/dnc/edc/v1/inventorydepot/config/${configId}`,
      {
        method: 'PUT',
        body: JSON.stringify(reqData),
        headers: {
          Authorization: `Bearer ${session.get('access_token')}`,
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      },
    )
      .then(response => response.json())
      .catch(error => console.error('Error: ', error));
  };

  ouColumns = [
    {
      title: 'OU Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'ID',
      key: 'ou',
      dataIndex: 'id',
    },
    {
      title: 'Status',
      key: 'action',
      render: record => (
        <span>
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Deactivated"
            defaultChecked={record && record.enabled}
            onChange={this.onChangeHandler(record)}
          />
        </span>
      ),
    },
  ];

  render() {
    const {
      checked, authenticated, locationsLoading, meta, form,
    } = this.props;

    const { reportingOus, ouConfigQuery } = this.state;

    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
    };

    return (
      <StyledContent>
        <Helmet>
          <title>Reports Portal</title>
          <meta name="description" content="Enterprise Data Explorer Reports Portal" />
        </Helmet>
        <PageHeader title="Reports">
          <h1>Inventory Config Page</h1>
        </PageHeader>
        <Section>
          <Card loading={!!locationsLoading}>
            {checked && !authenticated ? (
              <Alert
                message="You must login to access the reports portal."
                description=""
                type="error"
              />
            ) : (
              <div>
                <Form>
                  <FormItem {...formItemLayout} label="Operating Unit" key="location">
                    {form.getFieldDecorator('location', {
                      rules: [
                        {
                          required: false,
                          message: 'Please select your location!',
                        },
                      ],
                      initialValue: 'Filter for a specific OU to configure',
                    })(
                      <Select
                        showSearch
                        placeholder="Please select a location"
                        key="location-select"
                        getPopupContainer={() => document.getElementById('layout-content')}
                        onChange={(e) => {
                          this.setState({ ouConfigQuery: e.split('|')[0] });
                        }}
                      >
                        <Select.Option value="all" key="all">
                          {'All'}
                        </Select.Option>
                        {reportingOus &&
                          reportingOus.map(reportingOu => (
                            <Select.Option
                              value={`${reportingOu.get('id')}|${reportingOu.get(
                                'name',
                              )}|${reportingOu.get('loc')}`}
                              key={reportingOu.get('id')}
                            >
                              {`${reportingOu.get('id')} - ${reportingOu.get('name')}`}
                            </Select.Option>
                          ))}
                      </Select>,
                    )}
                  </FormItem>
                </Form>
                <StyledTable
                  rowKey="key"
                  pagination={{ defaultPageSize: 20 }}
                  dataSource={
                    reportingOus
                      ? reportingOus
                          .toJS()
                          .filter(obj =>
                            (ouConfigQuery
                              ? ouConfigQuery === 'all' || obj.id === ouConfigQuery
                              : true),
                          )
                      : null
                  }
                  columns={this.ouColumns}
                  size="middle"
                  loading={!reportingOus}
                />
              </div>
            )}
          </Card>
        </Section>
      </StyledContent>
    );
  }
}

ReportsPortalUploadPage.propTypes = {
  locationsLoading: PropTypes.bool,
  locations: ImmutablePropTypes.map, // eslint-disable-line
  session: ImmutablePropTypes.map, // eslint-disable-line
  checked: PropTypes.bool,
  authenticated: PropTypes.bool,
  entitlements: PropTypes.object, // eslint-disable-line
  form: PropTypes.object, // eslint-disable-line
  meta: ImmutablePropTypes.map, // eslint-disable-line
  match: PropTypes.object, // eslint-disable-line
};

ReportsPortalUploadPage.defaultProps = {
  onLoadReports: () => console.warn('onLoadReports is not defined'), // eslint-disable-line
  locationsLoading: false,
  locations: fromJS({}),
  checked: false,
  authenticated: false,
  form: {},
};

const mapStateToProps = createStructuredSelector({
  locations: makeSelectLocations(),
  locationsLoading: makeSelectLocationsLoading(),
  validation: makeSelectValidation(),
  checked: makeSelectSessionChecked(),
  session: makeSelectSessionUser(),
  authenticated: makeSelectSessionAuthenticated(),
  entitlements: makeSelectSessionUserEntitlements(),
  meta: makeSelectMeta(),
});

const WrappedReportsPortalUploadPage = Form.create()(ReportsPortalUploadPage);

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(WrappedReportsPortalUploadPage);
